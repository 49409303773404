/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AttributeType } from 'Type/ProductList.type';

import { hideTooltip, showTooltip } from '../../store/BrandTooltip/BrandTooltip.action';
import { BrandsType } from '../../type/BrandsType';
import BrandAttributeValue from './BrandAttributeValue.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandAttributeValue/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brands: state.ConfigReducer.brandsConfig.ambrandlist
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandAttributeValue/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showTooltip: (itemRef, isShowImages, description, image) => dispatch(
        showTooltip(itemRef, isShowImages, description, image)
    ),
    hideTooltip: () => dispatch(hideTooltip())
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandAttributeValue/Container/BrandAttributeValueContainer */
export class BrandAttributeValueContainer extends PureComponent {
    static propTypes = {
        attribute: AttributeType.isRequired,
        brands: BrandsType.isRequired,
        hideTooltip: PropTypes.func.isRequired,
        showTooltip: PropTypes.func.isRequired
    };

    containerFunctions = {};

    containerProps() {
        const {
            brands: {
                items: allBrandsConfig = []
            } = {},
            attribute,
            showTooltip,
            hideTooltip
        } = this.props;

        const brandProperties = allBrandsConfig.find(
            (brand) => brand.brandId.toString() === attribute.attribute_value.toString()
        );

        const {
            image,
            short_description,
            url,
            brandId
        } = brandProperties;

        return {
            allBrandsConfig,
            image,
            short_description,
            url,
            brandId,
            showTooltip,
            hideTooltip,
            attribute
        };
    }

    render() {
        return (
            <BrandAttributeValue
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandAttributeValueContainer);
