import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {ReactElement} from 'Type/Common.type';

import './ProductCard.override.style';
import {appendWithStoreCode, isHomePageUrl} from "Util/Url";
import {ProductType} from "Component/Product/Product.config";
import ProductPrice from "Component/ProductPrice";

/** @namespace VusaApp/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderBrand(): ReactElement {
        return (<div />);
    }

    renderPrice(): ReactElement {
        const {
            getActiveProduct,
            productPrice,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            price_tiers: priceTiers,
            type_id: type,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        return (
            <div
                block={ this.className }
                elem="PriceWrapper"
            >
                <ProductPrice
                    price={ productPrice }
                    priceType={ type as ProductType }
                    tierPrices={ priceTiers }
                    isPreview={ baseType !== ProductType.CONFIGURABLE || type === baseType }
                    isSchemaRequired={ false }
                    mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderVisibleOnHover(): ReactElement {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }
        const { pathname = appendWithStoreCode('/') } = location;

        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { !isHomePageUrl(pathname) ? this.renderProductActions() : '' }
                </div>
            </>
        );
    }
}

export default ProductCardComponent;
