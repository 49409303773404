/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import BrandAttributeValue from '../../component/BrandAttributeValue';

const addTextToAddSections = (args, callback, instance) => {
    const {
        product: {
            attributes: {
                brand
            } = {}
        }
    } = instance.props;

    // eslint-disable-next-line max-len
    if (!brand) {
        return null;
    }

    const brandWrapper = callback(...args);

    return cloneElement(
        brandWrapper,
        brandWrapper.props,
        Children.map(
            brandWrapper.props.children,
            (child, i) => {
                const lastIndex = brandWrapper.props.children.length
                    ? brandWrapper.props.children.length - 1
                    : 0;

                if (i !== lastIndex) {
                    return child;
                }

                return (
                    <BrandAttributeValue attribute={ brand } />
                );
            }
        )
    );
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderBrand: addTextToAddSections
        }
    }
};
